import React from 'react'
import { Styling } from './styled.js'
import image from '../../../images/texture2.jpg';
import { Curtains, Plane } from "react-curtains";


const basicVs = `
    precision mediump float;
    
    attribute vec3 aVertexPosition;
    attribute vec2 aTextureCoord;
    
    uniform mat4 uMVMatrix;
    uniform mat4 uPMatrix;
    
    uniform mat4 uTextureMatrix0;
    
    varying vec3 vVertexPosition;
    varying vec2 vTextureCoord;
    
    void main() {
        gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);
        
        // varyings
        vVertexPosition = aVertexPosition;
        vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
    }
`;

const basicFs = `
    precision mediump float;

    varying vec3 vVertexPosition;
    varying vec2 vTextureCoord;
    
    uniform sampler2D uSampler0;
    
    uniform float uTime;
    
    void main() {
        vec2 textureCoord = vTextureCoord;
        // displace our pixels along the X axis based on our time uniform
        // textures coords are ranging from 0.0 to 1.0 on both axis
        textureCoord.x += sin(textureCoord.y * 25.0) * cos(textureCoord.x * 25.0) * (cos(uTime / 50.0)) / 25.0;
        
        gl_FragColor = texture2D(uSampler0, textureCoord);
    }
`;


export default function Texture(data) {
    const basicUniforms = {
        time: {
          name: "uTime",
          type: "1f",
          value: 0
        }
      };
    
      const onRender = (plane) => {
        plane.uniforms.time.value++;
      };

      
      
      const {opacity, position, top, left, bottom, watchScroll, height, className} = data;
      
    return (
        <Styling 
        opacity={opacity ? opacity : '1'}
        position={position ? position : 'absolute'}
        top={top ? top : '0'}
        height={height ? height : '100%'}
        left={left ? left : '0'}
        bottom={bottom ? bottom : 'inherit'}
        className={className}
        >
            <div className={`texture_outer`}>
                <div className={data.className}>
                    {/* <Curtains pixelRatio={Math.min(1.5, typeof window !== 'undefined' ? window.devicePixelRatio : '')} watchScroll={watchScroll}>
                        <Plane
                        className="BasicPlane"
                        // plane init parameters
                        vertexShader={basicVs}
                        fragmentShader={basicFs}
                        uniforms={basicUniforms}
                        // plane events
                        onRender={onRender}>
                            <img className="image" src={image} alt="Logo" />
                        </Plane>
                    </Curtains> */}

                    <img className="just_image" src={image} alt="Logo" />
                </div>
            </div>
        </Styling>
    )
}

