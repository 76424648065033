import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
mix-blend-mode: darken;
width: 100%;
opacity: ${props => props.opacity};
height:${props => props.height};;
position: ${props => props.position};
top:${props => props.top};
left:${props => props.left};
bottom:${props => props.bottom};
margin: 0;
z-index: 10;
pointer-events: none;



    .texture{
    width: 100%;
    height: 70%;
    position: absolute;
    top:15%;
    left:0;
    margin: 0;

        &.full{
        height: 100%;
        top:-100px;
        }
        &.fullViewpoint{
        height: 100vh;
        top:0;
        }

    }

    .just_image,
    .texture_outer{
        width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: darken;
    background: var(--blue_light);
    }

    .just_image{
    object-fit: cover;
    position: fixed;

        @media only screen and (min-width: 760px) {
        /* position: absolute; */
        }


    }

    .BasicPlane {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    margin: 0;
    }

    .BasicPlane img {
    display: none;
    width:100vw;
    height:100vh;
    object-fit: cover;
    }

    .curtains-canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    right:0;
    background:var(--blue_light);

        canvas{
        background:var(--blue_light);
        }

    }


`

export { Styling }